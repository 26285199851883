export const getGameQuery = /* GraphQL */ `
  query GetGame($id: ID!) {
    getGame(id: $id) {
      id
      externalId
      title
      description
      category
      rating
      events {
        nextToken
      }
      branding {
        id
        name
        primaryColor
        secondaryColor
        status
        createdAt
        updatedAt
      }
      images
      status
      createdAt
      updatedAt
      brandingGuideGamesId
      events {
        items {
          id
          title
          subtitle
          description
          location
          partnerOnly
          scheduledStart
          scheduledEnd
          registrations {
            items {
                userProfile {
                    id
                }
            }
          }
          firstPlace {
            prize {
              id
              type
              title
              description
              image
              value
              status
              createdAt
              updatedAt
            }
          }
          secondPlace {
            prize {
              id
              type
              title
              description
              image
              value
              status
              createdAt
              updatedAt
            }
          }
          thirdPlace {
            prize {
              id
              type
              title
              description
              image
              value
              status
              createdAt
              updatedAt
            }
          }
          fourthPlace {
            prize {
              id
              type
              title
              description
              image
              value
              status
              createdAt
              updatedAt
            }
          }
          entryFee
          owner
          eventFirstPlaceId
          eventFourthPlaceId
          eventSecondPlaceId
          eventThirdPlaceId
          game {
            id
            externalId
            title
            description
            category
            rating
            images
            status
            createdAt
            updatedAt
            brandingGuideGamesId
          }
          streamUrl
          images
          status
          createdAt
          updatedAt
          providerProfileEventsId
          gameEventsId
          eventFirstPlaceId
          eventSecondPlaceId
          eventThirdPlaceId
        }
      }
    }
  }
`;

// export const getEventQuery = /* GraphQL */ `
// query GetEvent($id: ID!) {
//   getEvent(id: $id) {
//     id
//     title
//     description
//     location
//     scheduledStart
//     scheduledEnd
//     registrations {
//       nextToken
//     }
//     registrations {
//         items {
//             userProfile {
//                 userPoolId
//             }
//         }
//     }
//     prizes {
//       items {
//         prizeId
//         prize {
//           description
//           id
//           image
//           status
//           title
//           type
//           value
//         }
//       }
//     }
//     entryFee
//     owner
//     game {
//       id
//       externalId
//       title
//       description
//       category
//       rating
//       images
//       status
//       createdAt
//       updatedAt
//       brandingGuideGamesId
//     }
//     streamUrl
//     images
//     status
//     createdAt
//     updatedAt
//     providerProfileEventsId
//     gameEventsId
//   }
// }
// `;

export const getEventQuery = /* GraphQL */ `
  query GetEvent($id: ID!) {
      getEvent(id: $id) {
        id
        title
        subtitle
        tournaments
        description
        location
        partnerOnly
        scheduledStart
        scheduledEnd
        discordChannelId
        discordRoleId
        discordAddTime
        discordRemoveTime
        registrationCutoff
        registrationMessage
        eventType
        registrations {
          items {
              userProfile {
                  id
              }
          }
        }
      firstPlace {
        prize {
          id
          type
          title
          description
          image
          value
          status
          createdAt
          updatedAt
        }
      }
      secondPlace {
        prize {
          id
          type
          title
          description
          image
          value
          status
          createdAt
          updatedAt
        }
      }
      thirdPlace {
        prize {
          id
          type
          title
          description
          image
          value
          status
          createdAt
          updatedAt
        }
      }
      fourthPlace {
        prize {
          id
          type
          title
          description
          image
          value
          status
          createdAt
          updatedAt
        }
      }
      entryFee
      owner
      eventFirstPlaceId
      eventFourthPlaceId
      eventSecondPlaceId
      eventThirdPlaceId
      game {
        id
        externalId
        title
        description
        category
        rating
        images
        status
        createdAt
        updatedAt
        brandingGuideGamesId
      }
      streamUrl
      images
      status
      createdAt
      updatedAt
      providerProfileEventsId
      gameEventsId
      eventFirstPlaceId
      eventSecondPlaceId
      eventThirdPlaceId
      registrationQuestion
    }
  }
`;

export const listEventsQuery = `
query listEventsQuery {
  listEvents {
    items {
      description
      createdAt
      entryFee
      firstPlace {
        createdAt
        description
        id
        image
        status
        title
        type
        updatedAt
        value
      }
      id
      images
      location
      owner
      platform
      secondPlace {
        createdAt
        description
        id
        image
        status
        title
        type
        updatedAt
        value
      }
      status
      streamUrl
      thirdPlace {
        createdAt
        description
        id
        image
        status
        type
        title
        updatedAt
        value
      }
      title
      updatedAt
      registrations {
        items {
          userProfile {
            id
            firstName
            lastName
            username
            status
          }
        }
      }
    }
  }
}
`

export const getProviderProfile = /* GraphQL */ `
  query GetProviderProfile($id: ID!) {
    getProviderProfile(id: $id) {
      id
      name
      fiberHomesId
      harperId
      owners {
        nextToken
      }
      description
      branding {
        id
        name
        primaryColor
        secondaryColor
        status
        createdAt
        updatedAt
      }
      events {
        nextToken
      }
      urls
      images
      zipcodes
      status
      darkLogo
      discordRoleId
      discordChannelId
      createdAt
      updatedAt
      brandingGuideProviderProfilesId
      organizationProvidersId
      externalAuth
      useAccountNumber
      clash
      kansasClash
      noAccountPassword
      useLastName
    }
  }
`;
export const listProviderProfiles = /* GraphQL */ `
  query ListProviderProfiles(
    $filter: ModelProviderProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProviderProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        fiberHomesId
        harperId
        description
        urls
        images
        zipcodes
        status
        darkLogo
        discordRoleId
        discordChannelId
        createdAt
        updatedAt
        brandingGuideProviderProfilesId
        organizationProvidersId
        externalAuth
        slug
        useAccountNumber
        verifyAccountNumberPath
        verifyPlansPath
        clash
        kansasClash
        noAccountPassword
        useLastName
      }
      nextToken
    }
  }
`;

export const getProviderProfileInviteCodes = /* GraphQL */ `
  query GetProviderProfile($id: ID!) {
    getProviderProfile(id: $id) {
      id
      inviteCodes {
        items {
          id
          school
          createdAt
          deletedAt
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;