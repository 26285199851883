/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://407xjw5edl.execute-api.us-east-1.amazonaws.com/beta",
            "region": "us-east-1"
        },
        {
            "name": "fgnapi",
            "endpoint": "https://94mc11b8vf.execute-api.us-east-1.amazonaws.com/beta",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://ty5uaoyr2rbrlgwjmqs6m4dham.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-alfpldtqwzasbhpcdozkzhxspe",
    "aws_cognito_identity_pool_id": "us-east-1:da4d3a54-528f-46b6-b7eb-b02d0729652a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_qT1aORvBx",
    "aws_user_pools_web_client_id": "4sc6119odn5fcbvlor7unfe5f0",
    "oauth": {
        "domain": "fgn6c984e36-6c984e36-beta.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/oauthlogin/,http://localhost:3000/oauthlogin/",
        "redirectSignOut": "http://localhost:3000/oauthlogout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "fgnstorage13151-beta",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
