/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      description
      eventType
      subtitle
      location
      scheduledStart
      scheduledEnd
      registrations {
        nextToken
        __typename
      }
      firstPlace {
        id
        awardedAt
        place
        status
        createdAt
        updatedAt
        userProfilePrizeWinningsId
        prizeWinningPrizeId
        prizeWinningEventId
        __typename
      }
      secondPlace {
        id
        awardedAt
        place
        status
        createdAt
        updatedAt
        userProfilePrizeWinningsId
        prizeWinningPrizeId
        prizeWinningEventId
        __typename
      }
      thirdPlace {
        id
        awardedAt
        place
        status
        createdAt
        updatedAt
        userProfilePrizeWinningsId
        prizeWinningPrizeId
        prizeWinningEventId
        __typename
      }
      fourthPlace {
        id
        awardedAt
        place
        status
        createdAt
        updatedAt
        userProfilePrizeWinningsId
        prizeWinningPrizeId
        prizeWinningEventId
        __typename
      }
      giveaways {
        nextToken
        __typename
      }
      entryFee
      owner
      game {
        id
        externalId
        title
        description
        category
        rating
        images
        status
        createdAt
        updatedAt
        brandingGuideGamesId
        __typename
      }
      streamUrl
      images
      status
      tournaments
      partnerOnly
      productId
      priceId
      discordChannelId
      discordRoleId
      discordAddTime
      discordRemoveTime
      registrationCutoff
      registrationQuestion
      responses {
        nextToken
        __typename
      }
      registrationMessage
      createdAt
      updatedAt
      providerProfileEventsId
      gameEventsId
      eventFirstPlaceId
      eventSecondPlaceId
      eventThirdPlaceId
      eventFourthPlaceId
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        eventType
        subtitle
        location
        scheduledStart
        scheduledEnd
        entryFee
        owner
        streamUrl
        images
        status
        tournaments
        partnerOnly
        productId
        priceId
        discordChannelId
        discordRoleId
        discordAddTime
        discordRemoveTime
        registrationCutoff
        registrationQuestion
        registrationMessage
        createdAt
        updatedAt
        providerProfileEventsId
        gameEventsId
        eventFirstPlaceId
        eventSecondPlaceId
        eventThirdPlaceId
        eventFourthPlaceId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventResponses = /* GraphQL */ `
  query GetEventResponses($id: ID!) {
    getEventResponses(id: $id) {
      id
      event {
        id
        title
        description
        eventType
        subtitle
        location
        scheduledStart
        scheduledEnd
        entryFee
        owner
        streamUrl
        images
        status
        tournaments
        partnerOnly
        productId
        priceId
        discordChannelId
        discordRoleId
        discordAddTime
        discordRemoveTime
        registrationCutoff
        registrationQuestion
        registrationMessage
        createdAt
        updatedAt
        providerProfileEventsId
        gameEventsId
        eventFirstPlaceId
        eventSecondPlaceId
        eventThirdPlaceId
        eventFourthPlaceId
        __typename
      }
      submittedDetails
      userDetails {
        id
        discordId
        discordUsername
        provider
        zipCode
        address
        firstName
        lastName
        email
        birthdate
        sub
        status
        packages
        accountNumber
        accountLastName
        createdAt
        updatedAt
        inviteCodeUsersId
        __typename
      }
      createdAt
      updatedAt
      eventResponsesId
      eventResponsesUserDetailsId
      __typename
    }
  }
`;
export const listEventResponses = /* GraphQL */ `
  query ListEventResponses(
    $filter: ModelEventResponsesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        submittedDetails
        createdAt
        updatedAt
        eventResponsesId
        eventResponsesUserDetailsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserProfile = /* GraphQL */ `
  query GetUserProfile($id: ID!) {
    getUserProfile(id: $id) {
      id
      registrations {
        nextToken
        __typename
      }
      prizeWinnings {
        nextToken
        __typename
      }
      discord
      twitch
      youtube
      avatar
      phone
      status
      createdAt
      updatedAt
      providerProfileOwnersId
      __typename
    }
  }
`;
export const listUserProfiles = /* GraphQL */ `
  query ListUserProfiles(
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        discord
        twitch
        youtube
        avatar
        phone
        status
        createdAt
        updatedAt
        providerProfileOwnersId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserDetails = /* GraphQL */ `
  query GetUserDetails($id: ID!) {
    getUserDetails(id: $id) {
      id
      discordId
      discordUsername
      provider
      zipCode
      address
      firstName
      lastName
      email
      birthdate
      sub
      status
      packages
      inviteCode {
        id
        school
        deletedAt
        createdAt
        updatedAt
        providerProfileInviteCodesId
        __typename
      }
      accountNumber
      accountLastName
      createdAt
      updatedAt
      inviteCodeUsersId
      __typename
    }
  }
`;
export const listUserDetails = /* GraphQL */ `
  query ListUserDetails(
    $filter: ModelUserDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        discordId
        discordUsername
        provider
        zipCode
        address
        firstName
        lastName
        email
        birthdate
        sub
        status
        packages
        accountNumber
        accountLastName
        createdAt
        updatedAt
        inviteCodeUsersId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userDetailsBySub = /* GraphQL */ `
  query UserDetailsBySub(
    $sub: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userDetailsBySub(
      sub: $sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        discordId
        discordUsername
        provider
        zipCode
        address
        firstName
        lastName
        email
        birthdate
        sub
        status
        packages
        accountNumber
        accountLastName
        createdAt
        updatedAt
        inviteCodeUsersId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCheckoutSession = /* GraphQL */ `
  query GetCheckoutSession($id: ID!) {
    getCheckoutSession(id: $id) {
      id
      user {
        id
        discord
        twitch
        youtube
        avatar
        phone
        status
        createdAt
        updatedAt
        providerProfileOwnersId
        __typename
      }
      event {
        id
        title
        description
        eventType
        subtitle
        location
        scheduledStart
        scheduledEnd
        entryFee
        owner
        streamUrl
        images
        status
        tournaments
        partnerOnly
        productId
        priceId
        discordChannelId
        discordRoleId
        discordAddTime
        discordRemoveTime
        registrationCutoff
        registrationQuestion
        registrationMessage
        createdAt
        updatedAt
        providerProfileEventsId
        gameEventsId
        eventFirstPlaceId
        eventSecondPlaceId
        eventThirdPlaceId
        eventFourthPlaceId
        __typename
      }
      checkoutId
      customerId
      discordId
      tournament
      status
      createdAt
      updatedAt
      checkoutSessionUserId
      checkoutSessionEventId
      __typename
    }
  }
`;
export const listCheckoutSessions = /* GraphQL */ `
  query ListCheckoutSessions(
    $filter: ModelCheckoutSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCheckoutSessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        checkoutId
        customerId
        discordId
        tournament
        status
        createdAt
        updatedAt
        checkoutSessionUserId
        checkoutSessionEventId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTournamentRegistration = /* GraphQL */ `
  query GetTournamentRegistration($id: ID!) {
    getTournamentRegistration(id: $id) {
      id
      user {
        id
        discord
        twitch
        youtube
        avatar
        phone
        status
        createdAt
        updatedAt
        providerProfileOwnersId
        __typename
      }
      event {
        id
        title
        description
        eventType
        subtitle
        location
        scheduledStart
        scheduledEnd
        entryFee
        owner
        streamUrl
        images
        status
        tournaments
        partnerOnly
        productId
        priceId
        discordChannelId
        discordRoleId
        discordAddTime
        discordRemoveTime
        registrationCutoff
        registrationQuestion
        registrationMessage
        createdAt
        updatedAt
        providerProfileEventsId
        gameEventsId
        eventFirstPlaceId
        eventSecondPlaceId
        eventThirdPlaceId
        eventFourthPlaceId
        __typename
      }
      tournament
      createdAt
      updatedAt
      tournamentRegistrationUserId
      tournamentRegistrationEventId
      __typename
    }
  }
`;
export const listTournamentRegistrations = /* GraphQL */ `
  query ListTournamentRegistrations(
    $filter: ModelTournamentRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTournamentRegistrations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        tournament
        createdAt
        updatedAt
        tournamentRegistrationUserId
        tournamentRegistrationEventId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStripeCustomer = /* GraphQL */ `
  query GetStripeCustomer($id: ID!) {
    getStripeCustomer(id: $id) {
      id
      stripeId
      userId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStripeCustomers = /* GraphQL */ `
  query ListStripeCustomers(
    $filter: ModelStripeCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStripeCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stripeId
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProviderProfile = /* GraphQL */ `
  query GetProviderProfile($id: ID!) {
    getProviderProfile(id: $id) {
      id
      name
      fiberHomesId
      harperId
      owners {
        nextToken
        __typename
      }
      description
      branding {
        id
        name
        primaryColor
        secondaryColor
        status
        createdAt
        updatedAt
        __typename
      }
      events {
        nextToken
        __typename
      }
      urls
      images
      zipcodes
      status
      darkLogo
      discordRoleId
      discordChannelId
      organization {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      gldsProvider
      clientId
      externalAuth
      userInfoServerUrl
      discoveryDocument
      oidcIssuer
      authorizeUrl
      tokenUrl
      attributesUrl
      jwksUri
      authorizedCodes
      identityProviderCreated
      gldsCert
      slug
      providerPackages {
        nextToken
        __typename
      }
      marketingPackages {
        nextToken
        __typename
      }
      defaultMarketingPackage {
        id
        name
        topBanner
        eventCard
        popupAd
        default
        createdAt
        updatedAt
        providerProfileMarketingPackagesId
        __typename
      }
      inviteCodes {
        nextToken
        __typename
      }
      useAccountNumber
      noAccountPassword
      verifyAccountNumberPath
      verifyPlansPath
      clash
      kansasClash
      temporary
      useLastName
      createdAt
      updatedAt
      brandingGuideProviderProfilesId
      organizationProvidersId
      providerProfileDefaultMarketingPackageId
      __typename
    }
  }
`;
export const listProviderProfiles = /* GraphQL */ `
  query ListProviderProfiles(
    $filter: ModelProviderProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProviderProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        fiberHomesId
        harperId
        description
        urls
        images
        zipcodes
        status
        darkLogo
        discordRoleId
        discordChannelId
        gldsProvider
        clientId
        externalAuth
        userInfoServerUrl
        discoveryDocument
        oidcIssuer
        authorizeUrl
        tokenUrl
        attributesUrl
        jwksUri
        authorizedCodes
        identityProviderCreated
        gldsCert
        slug
        useAccountNumber
        noAccountPassword
        verifyAccountNumberPath
        verifyPlansPath
        clash
        kansasClash
        temporary
        useLastName
        createdAt
        updatedAt
        brandingGuideProviderProfilesId
        organizationProvidersId
        providerProfileDefaultMarketingPackageId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const providerProfilesByExternalAuth = /* GraphQL */ `
  query ProviderProfilesByExternalAuth(
    $externalAuth: String!
    $sortDirection: ModelSortDirection
    $filter: ModelProviderProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    providerProfilesByExternalAuth(
      externalAuth: $externalAuth
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        fiberHomesId
        harperId
        description
        urls
        images
        zipcodes
        status
        darkLogo
        discordRoleId
        discordChannelId
        gldsProvider
        clientId
        externalAuth
        userInfoServerUrl
        discoveryDocument
        oidcIssuer
        authorizeUrl
        tokenUrl
        attributesUrl
        jwksUri
        authorizedCodes
        identityProviderCreated
        gldsCert
        slug
        useAccountNumber
        noAccountPassword
        verifyAccountNumberPath
        verifyPlansPath
        clash
        kansasClash
        temporary
        useLastName
        createdAt
        updatedAt
        brandingGuideProviderProfilesId
        organizationProvidersId
        providerProfileDefaultMarketingPackageId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPrize = /* GraphQL */ `
  query GetPrize($id: ID!) {
    getPrize(id: $id) {
      id
      type
      title
      description
      image
      value
      status
      createdAt
      updatedAt
      eventGiveawaysId
      __typename
    }
  }
`;
export const listPrizes = /* GraphQL */ `
  query ListPrizes(
    $filter: ModelPrizeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrizes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        title
        description
        image
        value
        status
        createdAt
        updatedAt
        eventGiveawaysId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPrizeWinning = /* GraphQL */ `
  query GetPrizeWinning($id: ID!) {
    getPrizeWinning(id: $id) {
      id
      user {
        id
        discord
        twitch
        youtube
        avatar
        phone
        status
        createdAt
        updatedAt
        providerProfileOwnersId
        __typename
      }
      event {
        id
        title
        description
        eventType
        subtitle
        location
        scheduledStart
        scheduledEnd
        entryFee
        owner
        streamUrl
        images
        status
        tournaments
        partnerOnly
        productId
        priceId
        discordChannelId
        discordRoleId
        discordAddTime
        discordRemoveTime
        registrationCutoff
        registrationQuestion
        registrationMessage
        createdAt
        updatedAt
        providerProfileEventsId
        gameEventsId
        eventFirstPlaceId
        eventSecondPlaceId
        eventThirdPlaceId
        eventFourthPlaceId
        __typename
      }
      prize {
        id
        type
        title
        description
        image
        value
        status
        createdAt
        updatedAt
        eventGiveawaysId
        __typename
      }
      awardedAt
      place
      status
      createdAt
      updatedAt
      userProfilePrizeWinningsId
      prizeWinningPrizeId
      prizeWinningEventId
      __typename
    }
  }
`;
export const listPrizeWinnings = /* GraphQL */ `
  query ListPrizeWinnings(
    $filter: ModelPrizeWinningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrizeWinnings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        awardedAt
        place
        status
        createdAt
        updatedAt
        userProfilePrizeWinningsId
        prizeWinningPrizeId
        prizeWinningEventId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGame = /* GraphQL */ `
  query GetGame($id: ID!) {
    getGame(id: $id) {
      id
      externalId
      title
      description
      category
      rating
      events {
        nextToken
        __typename
      }
      branding {
        id
        name
        primaryColor
        secondaryColor
        status
        createdAt
        updatedAt
        __typename
      }
      images
      status
      createdAt
      updatedAt
      brandingGuideGamesId
      __typename
    }
  }
`;
export const listGames = /* GraphQL */ `
  query ListGames(
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        externalId
        title
        description
        category
        rating
        images
        status
        createdAt
        updatedAt
        brandingGuideGamesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBrandingGuide = /* GraphQL */ `
  query GetBrandingGuide($id: ID!) {
    getBrandingGuide(id: $id) {
      id
      name
      games {
        nextToken
        __typename
      }
      providerProfiles {
        nextToken
        __typename
      }
      primaryColor
      secondaryColor
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBrandingGuides = /* GraphQL */ `
  query ListBrandingGuides(
    $filter: ModelBrandingGuideFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrandingGuides(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        primaryColor
        secondaryColor
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      providers {
        nextToken
        __typename
      }
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProviderPackage = /* GraphQL */ `
  query GetProviderPackage($id: ID!) {
    getProviderPackage(id: $id) {
      id
      packageCode
      name
      description
      marketingPackage {
        id
        name
        topBanner
        eventCard
        popupAd
        default
        createdAt
        updatedAt
        providerProfileMarketingPackagesId
        __typename
      }
      providerProfile {
        id
        name
        fiberHomesId
        harperId
        description
        urls
        images
        zipcodes
        status
        darkLogo
        discordRoleId
        discordChannelId
        gldsProvider
        clientId
        externalAuth
        userInfoServerUrl
        discoveryDocument
        oidcIssuer
        authorizeUrl
        tokenUrl
        attributesUrl
        jwksUri
        authorizedCodes
        identityProviderCreated
        gldsCert
        slug
        useAccountNumber
        noAccountPassword
        verifyAccountNumberPath
        verifyPlansPath
        clash
        kansasClash
        temporary
        useLastName
        createdAt
        updatedAt
        brandingGuideProviderProfilesId
        organizationProvidersId
        providerProfileDefaultMarketingPackageId
        __typename
      }
      createdAt
      updatedAt
      providerProfileProviderPackagesId
      marketingPackageProviderPackageId
      __typename
    }
  }
`;
export const listProviderPackages = /* GraphQL */ `
  query ListProviderPackages(
    $filter: ModelProviderPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProviderPackages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        packageCode
        name
        description
        createdAt
        updatedAt
        providerProfileProviderPackagesId
        marketingPackageProviderPackageId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMarketingPackage = /* GraphQL */ `
  query GetMarketingPackage($id: ID!) {
    getMarketingPackage(id: $id) {
      id
      name
      topBanner
      eventCard
      popupAd
      providerPackage {
        nextToken
        __typename
      }
      providerProfile {
        id
        name
        fiberHomesId
        harperId
        description
        urls
        images
        zipcodes
        status
        darkLogo
        discordRoleId
        discordChannelId
        gldsProvider
        clientId
        externalAuth
        userInfoServerUrl
        discoveryDocument
        oidcIssuer
        authorizeUrl
        tokenUrl
        attributesUrl
        jwksUri
        authorizedCodes
        identityProviderCreated
        gldsCert
        slug
        useAccountNumber
        noAccountPassword
        verifyAccountNumberPath
        verifyPlansPath
        clash
        kansasClash
        temporary
        useLastName
        createdAt
        updatedAt
        brandingGuideProviderProfilesId
        organizationProvidersId
        providerProfileDefaultMarketingPackageId
        __typename
      }
      default
      createdAt
      updatedAt
      providerProfileMarketingPackagesId
      __typename
    }
  }
`;
export const listMarketingPackages = /* GraphQL */ `
  query ListMarketingPackages(
    $filter: ModelMarketingPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMarketingPackages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        topBanner
        eventCard
        popupAd
        default
        createdAt
        updatedAt
        providerProfileMarketingPackagesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInviteCode = /* GraphQL */ `
  query GetInviteCode($id: ID!) {
    getInviteCode(id: $id) {
      id
      school
      provider {
        id
        name
        fiberHomesId
        harperId
        description
        urls
        images
        zipcodes
        status
        darkLogo
        discordRoleId
        discordChannelId
        gldsProvider
        clientId
        externalAuth
        userInfoServerUrl
        discoveryDocument
        oidcIssuer
        authorizeUrl
        tokenUrl
        attributesUrl
        jwksUri
        authorizedCodes
        identityProviderCreated
        gldsCert
        slug
        useAccountNumber
        noAccountPassword
        verifyAccountNumberPath
        verifyPlansPath
        clash
        kansasClash
        temporary
        useLastName
        createdAt
        updatedAt
        brandingGuideProviderProfilesId
        organizationProvidersId
        providerProfileDefaultMarketingPackageId
        __typename
      }
      deletedAt
      users {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      providerProfileInviteCodesId
      __typename
    }
  }
`;
export const listInviteCodes = /* GraphQL */ `
  query ListInviteCodes(
    $filter: ModelInviteCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInviteCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        school
        deletedAt
        createdAt
        updatedAt
        providerProfileInviteCodesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventRegistration = /* GraphQL */ `
  query GetEventRegistration($id: ID!) {
    getEventRegistration(id: $id) {
      id
      eventId
      userProfileId
      event {
        id
        title
        description
        eventType
        subtitle
        location
        scheduledStart
        scheduledEnd
        entryFee
        owner
        streamUrl
        images
        status
        tournaments
        partnerOnly
        productId
        priceId
        discordChannelId
        discordRoleId
        discordAddTime
        discordRemoveTime
        registrationCutoff
        registrationQuestion
        registrationMessage
        createdAt
        updatedAt
        providerProfileEventsId
        gameEventsId
        eventFirstPlaceId
        eventSecondPlaceId
        eventThirdPlaceId
        eventFourthPlaceId
        __typename
      }
      userProfile {
        id
        discord
        twitch
        youtube
        avatar
        phone
        status
        createdAt
        updatedAt
        providerProfileOwnersId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEventRegistrations = /* GraphQL */ `
  query ListEventRegistrations(
    $filter: ModelEventRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventRegistrations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        userProfileId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventRegistrationsByEventId = /* GraphQL */ `
  query EventRegistrationsByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventRegistrationsByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        userProfileId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventRegistrationsByUserProfileId = /* GraphQL */ `
  query EventRegistrationsByUserProfileId(
    $userProfileId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventRegistrationsByUserProfileId(
      userProfileId: $userProfileId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        userProfileId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
